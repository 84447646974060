import React from 'react';
import { DependencyList, useCallback, useEffect, useState } from 'react';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/finally';

import { useLoader } from 'app/hooks/use-loader.hook';
import { useForceRefreshToken } from 'app/hooks/use-force-refresh-token.hook';
import { cancelRequest } from 'app/util/util';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';
import { Translation } from 'shared/components/translation/translation.component';

interface UseAjaxResponseOptions<T> {
  initialValue?: T;
  onError?: (err: any) => any;
  silent?: boolean;
  loaderName?: string;
}

interface UseAjaxResponseHook {
  <T>(factory: () => Observable<T>, deps: DependencyList, options?: UseAjaxResponseOptions<T>): [T, (silent?: boolean) => void];
}

// Assumes the response of the observable factory is a necessary part of state.
// On page load, it will submit the request to get the value that is added to state.
// invoking the refreshToken function will also force the hook to re-download the resource.
export const useAjaxResponse: UseAjaxResponseHook = (factory, deps, {initialValue = null, onError, silent: initialSilent = false, loaderName} = {} ) => {
  const toasts = useToastManager();
  const [refreshToken, forceRefresh] = useForceRefreshToken();
  const [response, setResponse] = useState(initialValue);
  const [silent, setSilent] = useState(initialSilent);
  const loader = useLoader(loaderName);
  const handleError = useCallback(() => toasts.error([<Translation resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE"/>]), [toasts]);

  useEffect(() => {
    const observable = factory();

    if (!observable) {
      return;
    }

    if (!silent) {
      loader.show();
      setResponse(initialValue);
    }

    const lastRequest = observable
      .finally(() => !silent && loader.hide())
      .subscribe(setResponse,
        onError ? onError : handleError);

    return () => cancelRequest(lastRequest);
  }, [...deps, refreshToken]);

  const forceRefreshCallback = useCallback((silentRefresh: boolean = false) => {
    setSilent(silentRefresh);
    forceRefresh();
  }, [setSilent, forceRefresh]);

  return [response, forceRefreshCallback];
};
